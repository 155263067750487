import * as React from 'react'
import {MdArrowBackIosNew, MdArrowForwardIos} from 'react-icons/md'

import {StaticImage} from 'gatsby-plugin-image'
import Slider from 'react-slick'
import {speratorLine, titleHeading} from '../fiveJBR/form.module.css'
import {SRLWrapper} from "simple-react-lightbox";

const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1008,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        }],
}


const Eminity = (props) => {

    return (<>
        <div className={'sm:px-0 px-2'}>
            <div>
                <h2 className={`text-center font-bold text-lg sm:text-2xl m-auto mt-8 ${titleHeading}`}>
                    DAMAC Lagoons Location</h2>
                <p className={'m-auto text-gray-700 my-6 text-center sm:w-2/3 w-full'}>
                    Located in the heart of the iconic Dubailand area, DAMAC Lagoons is located off Hessa Street. Close
                    to the popular DAMAC Hills community, DAMAC Lagoons is connected with main highways like Sheikh
                    Mohammed bin Zayed Road through Hessa Street. The strategic location puts the community in proximity
                    to major landmarks of Dubai
                </p>
                <div className={'pb-6 sm:pb-8 flex  sm:flex-row flex-col align-items-center'}>
                    <StaticImage
                        src={'../../images/damacLagoon/location1.png'}
                        alt={'LOCATION IN THE MASTER PLAN'}/>
                    <StaticImage
                        src={'../../images/damacLagoon/location2.png'}
                        alt={'LOCATION IN THE MASTER PLAN'}/>
                </div>
            </div>

        </div>
    </>)
}

export default Eminity
