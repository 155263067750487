import * as React from 'react'
// import ReactFlag from "../reactFlagsComp"
import { useForm } from 'react-hook-form'
import { addForm } from '../../action'
import { siteUrl } from '../_utils'
import { loaderText, telinputContainer } from '../fiveJBR/form.module.css'
import ReactFlag from '../reactFlagsComp-back'
import { ImSpinner } from 'react-icons/im'
import ReactGA from 'react-ga'
import FormComponent from '../formComponent'

const FormLayout = (props) => {

    const {onFormOpen} = props;

    const [loading, setLoading] = React.useState(false)

    return (<div className={`bg-white`}>
            <button disabled={loading}
                    style={{flex: 1, alignContent: 'center'}}
                    onClick={() => {
                        onFormOpen("brochure")
                        document.getElementById('downloadbrochure').style.display = 'block'
                    }}
              className={'w-10/12 rounded  text-lg  bg-primary-darkGold py-3 px-4 text-white'}
              type="submit">
                <span className={loaderText}>{loading && <ImSpinner className={'icon-spin '}/>}</span>
                <span>Download Brochure</span>
      </button>
      <small className={'block mb-8 mt-2 text-gray-500'}>One click to download a PDF brochure</small>

      <div className={'p-6 border border-primary-gray border-opacity-20 bg-gray-100'}>
          <div className={'text-bold text-l text-center text-sm text-primary-gray'}>
              <b>BOOK A VISIT SEE APARTMENTS</b> <br/>
              <small>Guaranteed Offers, you will not get anywhere else!</small>
          </div>

        <FormComponent formName={'crest'} mandatoryField={true} countryISO={props.countryISO}
                       buttonText={'Schedule an Appointment'}/>

        <div className={'text-center font-bold text-lg text-primary-gray'}>
          BOOKINGS OPEN!
        </div>
        <div className={'text-center text-sm text-primary-gray'}>
          Book Your Apartment with 10% only
        </div>

      </div>

    </div>
  )
}

export default FormLayout
