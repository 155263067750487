// extracted by mini-css-extract-plugin
export var mainSection = "heroArea-module--mainSection--Khelf";
export var headerSection = "heroArea-module--headerSection--1wyg4";
export var topSection = "heroArea-module--topSection--b4EO7";
export var bottomUnderLine = "heroArea-module--bottomUnderLine--4psS4";
export var footerList = "heroArea-module--footerList--2wHHZ";
export var heroText = "heroArea-module--heroText--1mI6x";
export var crestList = "heroArea-module--crestList--2mMv3";
export var sobhaHeading = "heroArea-module--sobhaHeading--21qH7";
export var sliderFooterContainer = "heroArea-module--sliderFooterContainer--2wBb6";
export var sliderFooter = "heroArea-module--sliderFooter--1Q8qP";
export var videoContainer = "heroArea-module--videoContainer--1mBU8";
export var gridBox = "heroArea-module--gridBox--1Q5en";
export var firstBlock = "heroArea-module--firstBlock--uyIDB";
export var gridBox2 = "heroArea-module--gridBox2--2YJQV";
export var area = "heroArea-module--area--3gdgm";
export var price = "heroArea-module--price--2D_8d";
export var downloadBtn = "heroArea-module--downloadBtn--t-tio";
export var firstGrid = "heroArea-module--firstGrid--17XHE";
export var gridText = "heroArea-module--gridText--1pJ8D";
export var minTextClass = "heroArea-module--minTextClass--2EA_6";
export var addressBlock = "heroArea-module--addressBlock--1Tkb4";
export var formBox = "heroArea-module--formBox--3__dk";