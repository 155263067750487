import * as React from 'react'
import {useRef} from 'react'
import useIntersectionObserver from '@react-hook/intersection-observer'
import {StaticImage} from 'gatsby-plugin-image'
import {
    videoContainer,
    gridBox,
    gridBox2,
    firstBlock,
    area,
    price,
} from './heroArea.module.css'
import {speratorLine, titleHeading, bulletPoint} from '../fiveJBR/form.module.css'

import Slider from "react-slick";

const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1008,
            settings: {
                slidesToShow: 1,
            },
        }],
}

const PaymentPlan = ({onFormOpen}) => {
    return <div className={'pb-0'}>

        <h2 id="paymentplan" className={`${titleHeading} font-bold text-lg sm:text-2xl m-auto mb-0 pt-0`}>
            3 Years Payment Plan
        </h2>
        <p className={'text-gray-500 mb-6'}></p>

        <div className="grid grid-cols-1 md:mb-4 md:grid-cols-2 lg:grid-cols-2 gap-4 sm:pb-0 pb-4">
            <div className={`${gridBox}`}>
                <div className={`${firstBlock}`}>
                    <h2>80%</h2>


                </div>
                <div>
                    <h4 className={'text-gray-700'}>
                        Down payment
                    </h4>
                </div>

            </div>
            {/*<div className={`${gridBox}`}>*/}
            {/*    <div className={`${firstBlock}`}>*/}
            {/*        <h2>55%</h2>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <h4 className={'text-gray-700'}>*/}
            {/*            Payable during construction*/}
            {/*        </h4>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={`${gridBox}`}>
                <div className={`${firstBlock}`}>
                    <h2>20%</h2>
                </div>
                <div>
                    <h4 className={'text-gray-700'}>
                        Payable on handover
                    </h4>
                </div>
            </div>
        </div>


        {/*<div className="text-center mt-8">*/}
        {/*    <button*/}
        {/*        style={{flex: 1, alignContent: 'center'}}*/}
        {/*        onClick={() => {*/}
        {/*            onFormOpen('payment')*/}
        {/*            document.getElementById('downloadbrochure').style.display = 'block'*/}
        {/*        }}*/}
        {/*        className={'rounded  text-lg  bg-primary-darkGold py-3 px-4 text-white'}*/}
        {/*        type="submit">*/}
        {/*        <span>Download Payment Plans </span>*/}
        {/*    </button>*/}
        {/*</div>*/}


    </div>
}

const Pricing = ({onFormOpen, brochureName}) => {
    return <div>

        <div>
            <p className={'text-gray-700 mt-4 mb-6'}>
                Welcome to Damac Lagoons. Nestled opposite Damac Hills, Lagoons is not a home but a holiday destination.
                Home to 8 different Mediterranean city-themed clusters, each with unique amenities, here you are
                surrounded
                by water everywhere. Come home to Portofino, Andalucia, Malta, Nice, Costa Brava, Santorini, Morocco,
                and
                Venice. Sun-kissed white beaches, crystal lagoons, waterside cafes, and dedicated spots for sporting and
                adrenaline junkies. At Lagoons, you live each day like a vacation.
            </p>

            <h2 id="paymentplan" className={`${titleHeading} font-bold text-lg sm:text-2xl m-auto mb-0 pt-0`}>
                Lagoons new launch, Luxury standalone villas and townhouses by Damac properties
            </h2>
            <p className={'mb-6'}>

            </p>

            <div>
                <StaticImage
                    srl_gallery_image="true"
                    src={'../../images/damacLagoon/maltaImage.jpg'}
                    alt={'Malta'}
                />

                <h2
                    className={`text-left font-bold text-lg pb-0 sm:text-4xl m-auto mt-4 pt-1`}>Malta</h2>

                <p className={'text-left text-lg mt-4 font-semibold'}>
                    3,4 & 5 Bedroom Luxury Townhouses at DAMAC Lagoons
                </p>
                <h3 className={'text-right sm:text-3xl text-2xl mt-4 text-darkGold'}>
                    AED 1,764,000
                </h3>
                <p className={'border-b-2 border-darkGold text-right w-56 mt-2 ml-auto'}>

                </p>

                <p className={'text-right text-sm mt-4 font-semibold text-darkGold'}>
                    STARTING PRICE
                </p>
            </div>
            <div className={'text-left mt-8'}>
                <button
                    style={{flex: 1, alignContent: 'center', marginRight: '40px', marginBottom: '20px'}}
                    onClick={() => {
                        onFormOpen('payment')
                        document.getElementById('downloadbrochure').style.display = 'block'
                    }}
                    className={'rounded  text-lg  bg-maltaColor py-3 px-4 text-white'}
                    type="submit">
                    <span>Download Payment Plans </span>
                </button>

                <button
                    style={{flex: 1, alignContent: 'center'}}
                    onClick={() => {
                        brochureName('malta')
                        onFormOpen('brochure')

                        document.getElementById('downloadbrochure').style.display = 'block'
                    }}
                    className={'rounded  text-lg  bg-maltaColor py-3 px-4 text-white'}
                    type="submit">
                    <span>Download Brochure </span>
                </button>
            </div>

            <p className={`${speratorLine}`}></p>

            <div>
                <StaticImage
                    srl_gallery_image="true"
                    src={'../../images/damacLagoon/venice.jpg'}
                    alt={'Venice'}
                />
                <h2
                    className={`text-left font-bold text-lg pb-0 sm:text-4xl m-auto mt-4 pt-1`}>Venice
                </h2>
                <p className={'text-left text-lg mt-4 font-semibold'}>
                    6 & 7 Bedroom large standalone villas with amazing lagoon
                </p>
                <h3 className={'text-right sm:text-3xl text-2xl mt-4 text-darkGold'}>
                    AED 4,985,000
                </h3>
                <p className={'border-b-2 border-darkGold text-right w-56 mt-2 ml-auto'}>

                </p>

                <p className={'text-right text-sm mt-4 font-semibold text-darkGold'}>
                    STARTING PRICE
                </p>
            </div>
            <div className={'text-left mt-8'}>
                <button
                    style={{flex: 1, alignContent: 'center', marginRight: '40px', marginBottom: '20px'}}
                    onClick={() => {
                        onFormOpen('payment')
                        document.getElementById('downloadbrochure').style.display = 'block'
                    }}
                    className={'rounded  text-lg  bg-veniceColor py-3 px-4 text-white'}
                    type="submit">
                    <span>Download Payment Plans </span>
                </button>

                <button
                    style={{flex: 1, alignContent: 'center'}}
                    onClick={() => {
                        onFormOpen('brochure')
                        brochureName('venice')
                        document.getElementById('downloadbrochure').style.display = 'block'
                    }}
                    className={'rounded  text-lg  bg-veniceColor py-3 px-4 text-white'}
                    type="submit">
                    <span>Download Brochure </span>
                </button>
            </div>

            {/*<div className="grid grid-cols-1 md:mb-4 md:grid-cols-2 lg:grid-cols-2 gap-4">*/}

            {/*    <div className={`${gridBox2}`}>*/}
            {/*        <div className={`${firstBlock}`}>*/}

            {/*            <StaticImage*/}
            {/*                srl_gallery_image="true"*/}
            {/*                src={'../../images/damacLagoon/3-bedroom.png'}*/}
            {/*                alt={'3 Bedroom'}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <h2 className={'font-bold'}>3 Bedroom Villa</h2>*/}
            {/*            <p className={`${area} text-gray-700`}>1550 - 2012 sqft.</p>*/}
            {/*            <p className={`${price}`}><small>Starting from </small> <small>AED 1.5 Million*</small></p>*/}

            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <div className={`${gridBox2}`}>*/}
            {/*        <div className={`${firstBlock}`}>*/}
            {/*            <StaticImage*/}
            {/*                srl_gallery_image="true"*/}
            {/*                src={'../../images/damacLagoon/4-bedroom.png'}*/}
            {/*                alt={'4 Bedroom'}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <h2 className={'font-bold'}>4 Bedroom Villa</h2>*/}
            {/*            <p className={`${area} text-gray-700`}>1550 - 2280 sqft.</p>*/}
            {/*            <p className={`${price}`}><small>Starting from</small> <small>AED 1.7 Million*</small></p>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}

            {/*<div className="grid grid-cols-1 md:mb-4 md:grid-cols-2 lg:grid-cols-2 gap-4">*/}

            {/*    <div className={`${gridBox2}`}>*/}
            {/*        <div className={`${firstBlock}`}>*/}
            {/*            <StaticImage*/}
            {/*                srl_gallery_image="true"*/}
            {/*                src={'../../images/damacLagoon/5-bedroom.png'}*/}
            {/*                alt={'5 Bedroom'}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <h2 className={'font-bold'}>5 Bedroom Villa</h2>*/}
            {/*            <p className={`${area} text-gray-700`}>2200 - 3260 sqft.</p>*/}
            {/*            <p className={`${price}`}><small>Starting from</small> <small>AED 2.6 Million*</small></p>*/}

            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <div className={`${gridBox2}`}>*/}
            {/*        <div className={`${firstBlock}`}>*/}
            {/*            <StaticImage*/}
            {/*                srl_gallery_image="true"*/}
            {/*                src={'../../images/damacLagoon/7-bedroom.jpeg'}*/}
            {/*                alt={'7 Bedroom'}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <h2 className={'font-bold'}>7 Bedroom Villa</h2>*/}
            {/*            <p className={`${area} text-gray-700`}>7300 - 8000 sqft.</p>*/}
            {/*            <p className={`${price}`}><small>Starting from</small> <small>AED 7.295 Million*</small></p>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}

        </div>


        {/*<div className="text-center mt-8">*/}
        {/*    <button*/}
        {/*        style={{flex: 1, alignContent: 'center'}}*/}
        {/*        onClick={() => {*/}
        {/*            onFormOpen('interior')*/}
        {/*            document.getElementById('downloadbrochure').style.display = 'block'*/}
        {/*        }}*/}
        {/*        className={'rounded  text-lg  bg-primary-darkGold py-3 px-4 text-white'}*/}
        {/*        type="submit">*/}
        {/*        <span>See Interior Design </span>*/}
        {/*    </button>*/}
        {/*</div>*/}
    </div>
}

const LazyIframe = ({url, title}) => {
    const containerRef = useRef()
    const lockRef = useRef(false)
    const {isIntersecting} = useIntersectionObserver(containerRef)
    if (isIntersecting) {
        lockRef.current = true
    }
    return (
        <div
            style={{
                // overflow: 'hidden',
                // paddingTop: '56.25%',
                // position: 'relative',
                width: '100%',
            }}
            ref={containerRef}
        >
            {lockRef.current && (
                <iframe
                    title={title}
                    style={{
                        bottom: 0,
                        height: '100%',
                        left: 0,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        width: '100%',
                    }}
                    src={url}
                    frameborder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="allowfullscreen"
                ></iframe>
            )}
        </div>
    )
}

const FloorPlan = (props) => {
    return <div id={'fplan'}>
        <div>
            <h2 id={'floorplan'}
                className={`${titleHeading}  font-bold text-lg pb-0 sm:text-2xl m-auto mb-0 pt-1`}>Floor Plans</h2>
            <div className={'mb-8'}>
                <Slider {...settings}>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/damacLagoon/3&4villa.png'}
                            alt={'3 & 4 BR Villa'}
                        />

                    </div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/damacLagoon/5villa.png'}
                            alt={'5 BR Villa'}
                        />
                    </div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/damacLagoon/7-1villa.png'}
                            alt={'7-1villa'}
                        />
                    </div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/damacLagoon/7-2villa.png'}
                            alt={'7-2villa'}
                        /></div>
                </Slider>
            </div>
        </div>
        <div className="text-center mt-8">
            <button
                style={{flex: 1, alignContent: 'center'}}
                onClick={() => {
                    if (props.onFormOpen) {
                        props.onFormOpen('floor')
                    }
                    document.getElementById('downloadbrochure').style.display = 'block'
                }}
                className={'rounded  text-lg  bg-primary-darkGold py-3 px-4 text-white'}
                type="submit">
                <span>Download Floor Plans </span>
            </button>
        </div>
        <p className={`${speratorLine}`}></p>
    </div>
}

const Connectivity = (props) => {
    const [loading, setLoading] = React.useState(false)

    return (<>
        <div>

            <Pricing {...props}/>

            <p className={`${speratorLine}`}></p>

            <PaymentPlan {...props}/>

            <p className={`${speratorLine}`}></p>

            <div>

                <h2 className={`${titleHeading} font-bold text-lg sm:text-2xl m-auto mb-0 pt-0`}>
                    DAMAC Lagoons
                </h2>

                <p className={'pb-0 m-auto text-gray-500 mb-6'}>
                </p>

                <div className={videoContainer}>
                    <LazyIframe url={"https://www.youtube.com/embed/anWXCF0r6PI"} title={"Damac Lagoons"}/>
                </div>


                <p className={`${speratorLine}`}></p>
                <div>

                    <div className={'col-span-2'}>
                        <ul className={`${bulletPoint}`}>
                            <li>Waterfront Villas in a Gated Community</li>
                            <li>Divided into 8 Mediterranean city-themed clusters</li>
                            <li>Fantastic Community opposite Damac Hills</li>
                            <li>3 BR Townhouses starting at AED 1.5 M</li>
                            <li>Regional Mall in the community</li>
                            <li>Water Integrated Amphitheatre</li>
                            <li>Crystal Lagoon Experience</li>
                            <li>First Water Cinema (Floating Cinema)</li>
                        </ul>
                    </div>

                </div>

            </div>

            <p className={`${speratorLine}`}></p>

            {/*<div className={'text-center pb-6 sm:pb-8'}>*/}

            {/*    <div className={'pb-0'}>*/}

            {/*        <SRLWrapper>*/}

            {/*            <Slider {...settings}>*/}
            {/*                <div>*/}
            {/*                    <a href={DCR4}>*/}
            {/*                        <StaticImage*/}
            {/*                            srl_gallery_image="true"*/}
            {/*                            src={'../../images/damacLagoon/dcr4.jpg'}*/}
            {/*                            alt={'DRIFT INTO FANTASY'}*/}
            {/*                        />*/}
            {/*                        <h3 className={'pt-2'}>DRIFT INTO FANTASY</h3>*/}

            {/*                    </a>*/}
            {/*                </div>*/}
            {/*                <div>*/}
            {/*                    <a href={DCR5}>*/}
            {/*                        <StaticImage*/}
            {/*                            srl_gallery_image="true"*/}
            {/*                            src={'../../images/damacLagoon/dcr5.jpg'}*/}
            {/*                            alt={'TASTE SOMETHING FASCINATING'}*/}
            {/*                        />*/}
            {/*                        <h3 className={'pt-2'}>TASTE SOMETHING FASCINATING</h3>*/}

            {/*                    </a>*/}
            {/*                </div>*/}
            {/*                <div>*/}
            {/*                    <a href={DCR6}>*/}
            {/*                        <StaticImage*/}
            {/*                            srl_gallery_image="true"*/}
            {/*                            src={'../../images/damacLagoon/dcr6.jpg'}*/}
            {/*                            alt={'CONTEMPORARY LIVING'}*/}
            {/*                        />*/}
            {/*                        <h3 className={'pt-2'}>CONTEMPORARY LIVING</h3>*/}

            {/*                    </a>*/}
            {/*                </div>*/}
            {/*                <div>*/}
            {/*                    <a href={DCR8}>*/}
            {/*                        <StaticImage*/}
            {/*                            srl_gallery_image="true"*/}
            {/*                            src={'../../images/damacLagoon/dcr8.jpg'}*/}
            {/*                            alt={'NESTLED IN THE LAP'}*/}
            {/*                        />*/}
            {/*                        <h3 className={'pt-2'}>NESTLED IN THE LAP</h3>*/}

            {/*                    </a>*/}
            {/*                </div>*/}

            {/*            </Slider>*/}

            {/*        </SRLWrapper>*/}

            {/*        <div className="text-center mt-8">*/}
            {/*            <button*/}
            {/*                style={{flex: 1, alignContent: 'center'}}*/}
            {/*                onClick={() => {*/}
            {/*                    props.onFormOpen('brochure')*/}
            {/*                    document.getElementById('downloadbrochure').style.display = 'block'*/}
            {/*                }}*/}
            {/*                className={'rounded  text-lg  bg-primary-darkGold py-3 px-4 text-white'}*/}
            {/*                type="submit">*/}
            {/*                <span>Download Brochure </span>*/}
            {/*            </button>*/}
            {/*        </div>*/}

            {/*    </div>*/}

            {/*    <p className={`${speratorLine} `}></p>*/}


            {/*</div>*/}

            <FloorPlan {...props}/>


        </div>
    </>)
}

export default Connectivity
