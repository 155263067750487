import * as React from 'react'

import SimpleReactLightbox from 'simple-react-lightbox'

import NavHeader from '../components/NavHeader'
import HeroArea from '../components/damacLagoonsComp/heroArea'
import Connectivity from '../components/damacLagoonsComp/connectivities'
import Eminity from '../components/damacLagoonsComp/eminity'
import Gallery from '../components/damacLagoonsComp/gallery'
import Plan from '../components/damacLagoonsComp/plan'
import Footer from '../components/damacLagoonsComp/footer'
import StickyForm from '../components/damacLagoonsComp/stickyForm'
import PrivatePool from '../images/damacLagoon/dcr7.jpg'

import {StaticImage} from 'gatsby-plugin-image'
import SEO from '../components/App/SEO'
import {siteUrl} from '../components/_utils'
import {useEffect, useState} from 'react'
import {getCountry} from '../action'
import {useScrollTracker} from 'react-scroll-tracker'
import ReactGA from 'react-ga'
import {contentDiv} from '../styles/sobha.module.css'
import StickyBox from 'react-sticky-box'
import ModalPage from '../components/modalPage1'

const shortNumber = require('short-number')

let dollarAmount = 265450

const Lagoons = ({data}) => {

    const [countryISO, setCountryISO] = useState('ae')
    const [short, setShort] = useState(shortNumber(dollarAmount))
    const [symbol, setSymbol] = useState('$')
    const [downloadType, setDownloadFormType] = useState(null)
    const [brochureType, setBrochureType] = useState(null)

    useEffect(() => {
        getCountry({amount: dollarAmount}).then((data) => {
            if (data.short) {
                setShort(data.short)
            }
            if (data.symbol) {
                setSymbol(data.symbol)
            }
            if (data.iso) {
                setCountryISO(data.iso.toLowerCase())
            }
        })
    }, [])

    if (typeof window !== 'undefined') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useScrollTracker([10, 15, 25, 50, 75, 100], ({scrollY}) => {
            ReactGA.event({
                category: 'scroll',
                action: scrollY,
            })
        })
    }

    const logoComp = (
        <StaticImage
            className="w-32 md:w-56 lg:w-56  align-middle"
            placeholder="tracedSVG"
            src={'../images/damacLagoon/damacLogo.png'}
            alt={'Sobha Hartland'}
        />
    )

    const titleParams = {
        title: `Damac Lagoons | ${siteUrl}`,
        ogTitle: `Damac Lagoons | ${siteUrl}`,

        description: 'Where Escaping is staying | Enjoy the perks of living by a crystal lagoon in central Dubai, Caribbean-style crystal lagoon facing residences',
        ogDescription: 'Where Escaping is staying | Enjoy the perks of living by a crystal lagoon in central Dubai, Caribbean-style crystal lagoon facing residences',
        keywords: '5 YEAR PAYMENT PLAN',

        secureImageUrl: `${PrivatePool}`,
        ogImage: `${PrivatePool}`,
        ogImageAlt: 'The Crest',

        orUrl: `${siteUrl}damac-lagoons/`,
        pageLink: `${siteUrl}damac-lagoons/`,
        canonicalRef: `${siteUrl}damac-lagoons/`,
    }

    return (
        <SimpleReactLightbox>
            <SEO data={titleParams}/>
            <NavHeader logoComp={logoComp}/>

            <ModalPage brochureType={brochureType} downloadType={downloadType}
                       onClose={() => {
                           setDownloadFormType(null)
                           setBrochureType(null)
                       }
                       }
                       formName={'damac-lagoons'} countryISO={countryISO}/>

            <HeroArea
                countryISO={countryISO}
                onFormOpen={(v) => {
                    setDownloadFormType(v)
                }}
                short={short}
                symbol={symbol}/>

            <div className={`${contentDiv}`}>
                <div className="grid grid-cols-12 gap-1">
                    <div className="bg-red col-span-12 lg:col-span-8 lg:pr-16">
                        <Connectivity brochureName={(val) => {
                            setBrochureType(val)
                        }} onFormOpen={(v) => {
                            setDownloadFormType(v)
                        }}/>

                    </div>
                    <div className="col-span-4 hidden lg:block">
                        <StickyBox offsetTop={20} offsetBottom={20}>
                            <StickyForm onFormOpen={(v) => {
                                setDownloadFormType(v)
                            }} countryISO={countryISO}/>
                        </StickyBox>
                    </div>
                </div>


                <Gallery onFormOpen={(v) => {
                    setDownloadFormType(v)
                }}/>

            </div>

            <Eminity onFormOpen={(v) => {
                setDownloadFormType(v)
            }}/>
            {/*<Plan/>*/}
            <Footer/>
        </SimpleReactLightbox>
    )
}

export default Lagoons
