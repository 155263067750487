import * as React from 'react'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'

import { StaticImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import { speratorLine, titleHeading } from '../fiveJBR/form.module.css'
import { SRLWrapper } from 'simple-react-lightbox'
import AQUA1 from '../../images/damacLagoon/office.png'
import AQUA2 from '../../images/damacLagoon/library.png'
import AQUA3 from '../../images/damacLagoon/gym.png'
import AQUA4 from '../../images/damacLagoon/music.png'
import AQUA5 from '../../images/damacLagoon/outdoorRooms.png'
import AQUA6 from '../../images/damacLagoon/spa.png'
import tile1 from '../../images/damacLagoon/damacTile1.png'
import tile2 from '../../images/damacLagoon/damacTile2.png'
import tile3 from '../../images/damacLagoon/damacTile3.png'
import tile4 from '../../images/damacLagoon/damacTile4.png'
import tile5 from '../../images/damacLagoon/damacTile5.png'
import tile6 from '../../images/damacLagoon/damacTile6.png'
import tile7 from '../../images/damacLagoon/damacTile7.png'
import tile8 from '../../images/damacLagoon/damacTile8.png'

const settings = {
  infinite: true,
  slidesToShow: 4,
  centerMode: true,
  slidesToScroll: 1,
  centerPadding: '60px',
  speed: 500,
  arrows: true,
  dots: true,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1008,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const Eminity = (props) => {

  return (<>
    <div className={'sm:px-0 px-2 myTestClass'}>
      <div className={'pb-0'}>
        <h2 className={` text-center font-bold text-lg sm:text-2xl mb-4 m-auto ${titleHeading}`}>Gallery</h2>
        <SRLWrapper>

          <Slider {...settings}>
            <div>
              <a style={{padding: '10px', display: 'block'}} href={tile1}>
                <StaticImage
                  srl_gallery_image="true"
                  src={'../../images/damacLagoon/damacTile1.png'}
                />
              </a>
            </div>
            <div>
              <a style={{padding: '10px', display: 'block'}} href={tile2}>
                <StaticImage
                  srl_gallery_image="true"
                  src={'../../images/damacLagoon/damacTile2.png'}
                />
              </a>
            </div>
            <div>
              <a style={{padding: '10px', display: 'block'}} href={tile3}>
                <StaticImage
                  srl_gallery_image="true"
                  src={'../../images/damacLagoon/damacTile3.png'}
                />
              </a>
            </div>
            <div>
              <a style={{padding: '10px', display: 'block'}} href={tile4}>
                <StaticImage
                  srl_gallery_image="true"
                  src={'../../images/damacLagoon/damacTile4.png'}
                />
              </a>
            </div>
            <div>
              <a style={{padding: '10px', display: 'block'}} href={tile5}>
                <StaticImage
                  srl_gallery_image="true"
                  src={'../../images/damacLagoon/damacTile5.png'}
                />
              </a>
            </div>
            <div>
              <a style={{padding: '10px', display: 'block'}} href={tile6}>
                <StaticImage
                  srl_gallery_image="true"
                  src={'../../images/damacLagoon/damacTile6.png'}
                />
              </a>
            </div>
            <div>
              <a style={{padding: '10px', display: 'block'}} href={tile7}>
                <StaticImage
                  srl_gallery_image="true"
                  src={'../../images/damacLagoon/damacTile7.png'}
                />
              </a>
            </div>
            <div>
              <a style={{padding: '10px', display: 'block'}} href={tile8}>
                <StaticImage
                  srl_gallery_image="true"
                  src={'../../images/damacLagoon/damacTile8.png'}
                />
              </a>
            </div>


          </Slider>

        </SRLWrapper>
      </div>
    </div>
  </>)
}

export default Eminity
